// modules
import React from 'react'
import { graphql } from 'gatsby'
// components
import Grid from '../components/shared/Grid/Grid'
import Card from '../components/shared/Card/Card'
import SEO from '../components/shared/SEO/SEO'

// definitions
function GalleriesPage({ data, location }) {
  const pageRoute = location.pathname
  const pageTitle = 'Permanent Galleries'
  const galleries = data.file.childMarkdownRemark.frontmatter.galleries

  return (
    <>
      <SEO title={pageTitle} route={pageRoute} />
      <h1>{pageTitle}</h1>
      {galleries.length ? (
        <Grid layout="fixed">
          {galleries.map((gallery, index) => {
            const name = gallery.name
            const city = gallery.city
            const url = gallery.url

            if (name) {
              return (
                <Card key={index} url={url}>
                  <h3>{city}</h3>
                  <p>{name}</p>
                </Card>
              )
            } else {
              return null
            }
          })}
        </Grid>
      ) : null}
    </>
  )
}

// exports
export default GalleriesPage

export const galleriesPageQuery = graphql`
  query {
    file(
      sourceInstanceName: { eq: "content" }
      relativePath: { eq: "pages/galleries.md" }
    ) {
      childMarkdownRemark {
        frontmatter {
          title
          galleries {
            name
            city
            url
          }
        }
      }
    }
  }
`
